@import url(https://fonts.googleapis.com/css2?family=Inter:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anybody:wght@400;600);
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}
html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Anybody', sans-serif;
  font-weight: 900;
}

#intro {
  position: fixed;
  bottom: 130px;
  width: 100%;
  z-index: 1;
  color: #fff;
  text-align: center;
}

h1 {
  font-size: 48px;
}
/* make the buttons small on a small screen */
button {
  border-radius: 15px;
  background-color: #000;
  color: #fff;
  padding: 12px 24px;
  font-size: 16px;
  font-family: 'Anybody', sans-serif;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

@media screen and (max-width: 600px) {
  button {
    padding: 6px 8px;
    font-size: 10px;
  }

  h1 {
    font-size: 32px;
  }
}

button:hover {
  background-color: #fff;
  color: #000;
}

a{
  color: #fff;
}

a:hover{
  background-color: #fff;
  color: #000;
}

p {
  font-family: 'Anybody', sans-serif;
  font-weight: 400;
  margin-left: 20%;
  margin-right: 20%;
}

